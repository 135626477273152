@font-face {
  font-family: 'Mona-Sans';
  src: url('./assets/font/Mona-Sans-UltraLight.eot');
  src: url('./assets/font/Mona-Sans-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Mona-Sans-UltraLight.woff2') format('woff2'),
    url('./assets/font/Mona-Sans-UltraLight.woff') format('woff'),
    url('./assets/font/Mona-Sans-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mona-Sans';
  src: url('./assets/font/Mona-Sans-Light.eot');
  src: url('./assets/font/Mona-Sans-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Mona-Sans-Light.woff2') format('woff2'),
    url('./assets/font/Mona-Sans-Light.woff') format('woff'),
    url('./assets/font/Mona-Sans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mona-Sans';
  src: url('./assets/font/Mona-Sans-Regular.eot');
  src: url('./assets/font/Mona-Sans-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Mona-Sans-Regular.woff2') format('woff2'),
    url('./assets/font/Mona-Sans-Regular.woff') format('woff'),
    url('./assets/font/Mona-Sans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mona-Sans';
  src: url('./assets/font/Mona-Sans-Medium.eot');
  src: url('./assets/font/Mona-Sans-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Mona-Sans-Medium.woff2') format('woff2'),
    url('./assets/font/Mona-Sans-Medium.woff') format('woff'),
    url('./assets/font/Mona-Sans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mona-Sans';
  src: url('./assets/font/Mona-Sans-SemiBold.eot');
  src: url('./assets/font/Mona-Sans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Mona-Sans-SemiBold.woff2') format('woff2'),
    url('./assets/font/Mona-Sans-SemiBold.woff') format('woff'),
    url('./assets/font/Mona-Sans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mona-Sans';
  src: url('./assets/font/Mona-Sans-Bold.eot');
  src: url('./assets/font/Mona-Sans-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Mona-Sans-Bold.woff2') format('woff2'),
    url('./assets/font/Mona-Sans-Bold.woff') format('woff'),
    url('./assets/font/Mona-Sans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mona-Sans';
  src: url('./assets/font/Mona-Sans-ExtraBold.eot');
  src: url('./assets/font/Mona-Sans-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/font/Mona-Sans-ExtraBold.woff2') format('woff2'),
    url('./assets/font/Mona-Sans-ExtraBold.woff') format('woff'),
    url('./assets/font/Mona-Sans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Mona-Sans';
  font-weight: 400;
  background-color: #130042;
  color: #FCFBFF;
  overflow-x: hidden;
}

a {
  color: #5cbda9;
  text-decoration: none;
}

a:hover {
  color: #ff437f;
  text-decoration: underline;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #5e4572;
}


.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #130042 !important;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.main_navbar {
  background: transparent;
  z-index: 100;
  position: fixed;
  width: 100%;
}

.main_navbar .container {
  background: hsl(257deg 100% 13% / 60%);
  background: rgb(40 25 89 / 100%);
  background: url("./assets/images/navbg.png") no-repeat center;
  background-size: 140%;
  padding: 8px 40px;
  border-radius: 60px;
}

.navbar-toggler {
  box-shadow: none !important;
}

.offcanvas {
  background: #1c0950;
}

.offcanvas-header .btn-close {
  filter: invert(1);
  box-shadow: none !important;
}

.navbar_right {
  display: flex;
  align-items: center;
}

.address_btn {
  background: transparent;
  border: 1px solid #C241FF;
  background: #281959;
  display: flex;
  color: #fff !important;
  text-decoration: none !important;
  align-items: center;
}

.dropdown-menu {
  background: #6f4bff;
}

.dropdown-item {
  color: #fff;
}


/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(132deg, #2d0a5f, #21104c);
}

.primary_modal .modal-header {
  border-bottom: 1px solid #2d0a5f;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #4c2f74;
  background-color: transparent;
  color: #fff;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}

.primary_modal .btn-close {
  filter: invert(1);
}

.connect_wallet_button:hover {
  border-color: #130042;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #fff;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}




.primary_btn {
  border-radius: 50px;
  background: linear-gradient(252deg, #5E4DFF 29.5%, #C242FF 79.5%);
  color: #FFF;
  padding: 10px 40px;
  border: 0;
}

.primary_btn:hover {
  background: linear-gradient(52deg, #5E4DFF 29.5%, #C242FF 79.5%);
}

.head {
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
}

.h2tag {
  color: #FCFBFF;
  font-size: 48px;
  font-weight: 500;
}

.h2tag span {
  background: linear-gradient(90deg, #C242FF 31.13%, #B2BCFF 51.97%, #CAF99B 72.81%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.96px;
}


.page_header {
  position: relative;
}

.page_header::before {
  content: "";
  background: url("./assets/images/banbg.png") no-repeat center;
  z-index: -1;
  position: absolute;
  width: 100%;
  top: -80px;
  height: 811px;
  left: 0;
  right: 0;
  margin: auto;
  background-size: 100% 100%;
}

.page_header h1 {
  color: #FCFBFF;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: -0.96px;
}

.page_header h1 span {
  background: linear-gradient(88deg, #C242FF 2.6%, #B2C0FF 62.32%, #CAF99B 103.72%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.page_header .img_box {
  position: relative;
  z-index: 1;
}

.page_header .img_box::before {
  content: "";
  position: absolute;
  border-radius: 388px;
  background: #C242FF;
  filter: blur(225px);
  width: 380px;
  height: 388px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;

}



.total {
  padding: 55px 0;
  padding-top: 95px;
}

.total .tot_box {
  border: 1px solid #3C287D;
  border-radius: 80px;
  background: url("./assets/images/box_lights.png") no-repeat center;
  background-size: 110%;
  padding: 55px 60px;
}

.total .tot_box h3 {
  background: radial-gradient(612.14% 305.92% at 14.22% -47.32%, #FFE9FF 0%, #C242FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 58px;
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
}

.total .tot_box p {
  font-size: 20px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
}

.total .box {
  display: flex;
  align-items: center;
}

.total .img_box {
  border-radius: 100px;
  background: radial-gradient(135% 135% at 50% -7%, #5E4DFF 0%, #C242FF 100%);
  box-shadow: 0px 16px 28px 0px rgba(194, 66, 255, 0.35);
  min-width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}

.total .right_box {
  border-left: 1px solid #533BA1;
  padding-left: 60px;
}



.features {
  padding: 55px 0;
  position: relative;
  z-index: 1;
}

.features::before {
  content: "";
  position: absolute;
  border-radius: 388px;
  background: rgba(202, 249, 155, 0.25);
  filter: blur(200px);
  width: 40%;
  height: 40%;
  z-index: -1;
  bottom: 0;
}

.features::after {
  content: "";
  position: absolute;
  border-radius: 388px;
  background: rgba(194, 66, 255, 0.50);
  filter: blur(200px);
  width: 40%;
  height: 40%;
  z-index: -1;
  top: 0;
  right: 0;
}

.features .img_box {
  position: absolute;
  background: #3C287D;
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -35px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
}

.features .img_box img {
  filter: drop-shadow(0px 8px 25px rgba(202, 249, 155, 0.70));
}

.features .box {
  background: url("./assets/images/boxbg.png") no-repeat center;
  background-size: contain;
  height: 313px;
  position: relative;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: .2s;
}

.features .box:hover {
  background: url("./assets/images/boxbg_hvr.png") no-repeat center;
  background-size: contain;
}

.features .box:hover .img_box {
  background: #CAF99B;
  filter: drop-shadow(0px 25px 50px rgba(202, 249, 155, 0.75));
}

.features .box:hover .img_box img {
  filter: invert(189%) sepia(227%) saturate(5648%) hue-rotate(233deg) brightness(90%) contrast(21%);
}

.features .box h6 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 25px;
}

.features .box p {
  margin-bottom: 0;
  color: #fff;
}




.levels {
  padding: 55px 0;
}

.levels .table-responsive {
  border-radius: 20px;
  border: 1px solid #3C287D;
  background: linear-gradient(0deg, #3C287D -75.43%, #130042 100%) !important;
}

.levels table {
  border-color: #5148A1;
  color: #FCFBFF !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}

.levels thead {
  border-bottom: 1px solid #3C287D;
  background: radial-gradient(120.01% 211.31% at 50% -15.06%, #3C287D 0%, #130042 100%) !important;
  color: #CAF99B !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.levels .table>:not(caption)>*>* {
  color: unset !important;
  background: unset !important;
}

.levels th {
  width: 33.33%;
}

.levels tr {
  transition: .3s;
}

.levels tr:hover {
  transition: .3s;
  background: linear-gradient(180deg, rgba(128, 141, 219, 0.25) 0%, rgba(93, 78, 221, 0.25) 100%);
}

.levels tbody tr:nth-last-child(1) {
  border-color: transparent;
}

.levels td,
.levels th {
  padding: 20px 15px !important;
}

.levels td:nth-child(1),
.levels th:nth-child(1) {
  padding-left: 100px !important;
}

.levels td:nth-child(2),
.levels th:nth-child(2) {
  text-align: center;
}

.levels td:nth-last-child(1) div,
.levels th:nth-last-child(1) div {
  width: fit-content;
  /* margin-left: auto; */
  padding-right: 100px !important;
}

.levels td:nth-last-child(1),
.levels th:nth-last-child(1) {
  text-align: center;
}




.platform_activity {
  margin: 0 auto;
  padding: 55px 0;
}

.platform_activity p {
  margin-bottom: 0;
}


.platform_activity p.green span {
  color: #feffff;
}


.platform_activity .activity_box_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0;
  padding: 20px 95px;
  border-radius: 10px;
  background: #281959;
  border: 1px solid transparent;
  margin-bottom: 5px;
}

.platform_activity .activity_box_flx:hover {
  border-radius: 10px;
  border: 1px solid #3C287D;
  background: radial-gradient(120.01% 211.31% at 50% -15.06%, #3C287D 0%, #130042 100%);
}

.platform_activity .activity_box_flx:nth-last-child(1) {
  margin-bottom: 20px;
}


.platform_activity .flxbox {
  display: flex;
  align-items: center;
}

.platform_activity .flxbox div {
  margin-right: 30px;
}

.platform_activity .flxbox .btn {
  background: #CAF99B;
  color: #130042;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  margin-right: 0;
  border: 0;
  padding: 7px 25px;
}

.platform_activity .rightbtn p {
  display: flex;
  align-items: center;
  color: #869CFF;
}

.platform_activity .rightbtn p:hover {
  filter: invert(0) brightness(1.5);
}

.platform_activity .rightbtn p span:nth-child(2) {
  margin-bottom: -5px;
}

.platform_activity .activity_box .seemorebutn {
  font-size: 18px;
  color: #fff;
  background: transparent;
  border: 0;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
}

.platform_activity .activity_box .seemorebutn .arrowhvr {
  display: none;
}

.platform_activity .activity_box .seemorebutn:hover .arrow {
  display: none;
}

.platform_activity .activity_box .seemorebutn:hover .arrowhvr {
  display: inline-block;
}



.tree_sec {
  padding: 55px 0;
}

.tree_sec .bg {
  background: url("./assets/images/tree_bg.png") no-repeat center;
  border-radius: 50px;
  padding: 70px;
}

.tree_sec .in_box {
  border-radius: 30px;
  border: 1px solid #3C287D;
  background: radial-gradient(120.01% 211.31% at 50% -15.06%, #3C287D 0%, #130042 100%);
  width: 66%;
  margin: auto;
  padding: 50px 30px;
}

.tree_sec .in_box .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tree_sec .in_box .box.bdr {
  border-bottom: 1px solid #533BA1;
  margin-bottom: 25px;
  padding-bottom: 15px;
}

.tree_sec .in_box p {
  font-size: 18px;
}

.tree_sec .in_box h5 {
  background: linear-gradient(90deg, #C242FF 0%, #B2BCFF 60.19%, #CAF99B 120.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
}



.faq {
  margin: 0 auto;
  padding: 55px 0;
}

.faq .accordion-item {
  padding: 0px 10px;
  margin: 0px 0;
  box-shadow: none !important;
  background: linear-gradient(#130042, #130042) padding-box, linear-gradient(90deg, rgba(81, 72, 161, 0.00) 4.74%, #5148A1 52.48%, rgba(81, 72, 161, 0.00) 96.42%) border-box;
  border-radius: 30px;
  border: 1px solid transparent;
  border-bottom: 0;
  color: #FCFBFF;
  transition: .3s;
}

.faq .accordion-item[aria-expanded="true"] {
  background: linear-gradient(180deg, #664BFF -27.78%, rgba(19, 0, 66, 0.00) 78.34%);
  border: 0;
  transition: .3s;
}

.faq .accordion-item:nth-child(1) {
  border-top: 0;
}

.faq .accordion-item .accordion-button {
  background: transparent;
  border: 0 !important;
  box-shadow: none;
  color: #CAF99B;
  font-weight: 600;
  font-size: 16px;
  padding: 25px 20px;
}

.faq .accordion-body {
  padding-top: 15px;
}

.faq .accordion-button::after {
  width: 12px;
  height: 8px;
  background-size: contain;
}

.faq .accordion-item:nth-child(1) .accordion-button::after {
  transform: none !important;
}

.faq [aria-expanded="true"] .accordion-button::after {
  background-image: url("./assets/images/down_arw.png");
}

.faq [aria-expanded="false"] .accordion-button::after {
  background-image: url("./assets/images/right_arw.png");
  width: 7px;
  height: 12px;
}



.footer {
  background: #0D002C;
  padding: 40px 0;
}

.footer p {
  color: #fff;
  margin-bottom: 0;
  word-break: break-word;
}

.footer .blue {
  color: #869CFF;
  margin: 20px 0;
}

.footer_panel {
  text-align: center;
}

.footer ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.footer li a {
  background: radial-gradient(135% 135% at 50% -7%, #5E4DFF 0%, #C242FF 100%);
  width: 44px;
  height: 44px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #fff;
  fill: #FCFBFF;
  box-shadow: 0px 13px 32px 0px rgba(255, 255, 255, 0.50) inset;
  text-decoration: none !important;
  filter: drop-shadow(0px 0px 5px rgba(157, 226, 255, 0.50));
}


.dashboard {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding-top: 100px;
}

.dashboard::before {
  content: "";
  position: absolute;
  border-radius: 388px;
  background: rgba(202, 249, 155, 0.25);
  filter: blur(200px);
  width: 40%;
  height: 40%;
  z-index: -1;
  bottom: 0;
}

.dashboard::after {
  content: "";
  position: absolute;
  border-radius: 388px;
  background: rgba(194, 66, 255, 0.50);
  filter: blur(200px);
  width: 40%;
  height: 40%;
  z-index: -1;
  top: 0;
  right: 0;
}

.dashboard .dashboard_shade {
  position: relative;
  z-index: 1;
  padding: 55px 0;
}

.dashboard .dashboard_shade::before {
  content: "";
  position: absolute;
  border-radius: 388px;
  background: rgba(202, 249, 155, 0.25);
  filter: blur(200px);
  width: 40%;
  height: 40%;
  z-index: -1;
  right: 0;
  bottom: 15%;

  border-radius: 696px;
  opacity: 0.75;
  background: rgba(94, 76, 255, 0.50);
  filter: blur(200px);
}

.dashboard .dashboard_shade::after {
  content: "";
  position: absolute;
  border-radius: 388px;
  background: rgba(194, 66, 255, 0.50);
  filter: blur(200px);
  width: 40%;
  height: 40%;
  z-index: -1;
  bottom: 0;
  left: 0;

  opacity: 0.75;
  background: rgba(194, 66, 255, 0.50);
  filter: blur(200px);
}

.dashboard .h_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.dashboard h3 {
  background: linear-gradient(90deg, #C242FF 0%, #B2BCFF 60.19%, #CAF99B 120.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}

.dashboard .h_flx .box {
  border-radius: 60px;
  display: flex;
  align-items: center;
  background: linear-gradient(110deg, #3C287D 27.59%, #130042 113.04%);
  padding: 10px 25px;
}

.dashboard .h_flx .box p {
  color: #FFF;
  margin-bottom: 0;
  font-weight: 500; 
  margin-left: 8px;
}

.dashboard .h_flx .box img {
  cursor: pointer;
  height: 15px;
  margin-bottom: 7px;
}

.dashboard .bg_box {
  border-radius: 60px;
  padding: 20px;
  background: radial-gradient(82.61% 82.61% at 26.3% 32.03%, #3C287D 0%, #130042 100%);
}

.dashboard .in_box {
  background: url("./assets/images/dashbox_bg.png") no-repeat center #130042;
  background-size: 120%;
  border-radius: 45px;
  padding: 45px 50px;
}

.dashboard .in_box .box {
  display: flex; 
  position: relative;
}

.dashboard .in_box .col-lg-3:not(:nth-last-child(1)) .box::before {
  content: "";
  position: absolute;
  right: 0;
  background: #533BA1;
  width: 1px;
  height: 130%;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.dashboard .in_box .box .img_box {
  border-radius: 50%;
  min-width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  border-radius: 30px;
  background: radial-gradient(135% 135% at 50% -7%, #5E4DFF 0%, #C242FF 100%);
  box-shadow: 0px 16px 28px 0px rgba(194, 66, 255, 0.35);
}

.dashboard .in_box .box h5 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  background: radial-gradient(612.14% 305.92% at 14.22% -47.32%, #FFE9FF 0%, #C242FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0px;
  word-break: break-word;
}

.dashboard .in_box .box span {
  color: #FCFBFF;
  font-size: 14px;
  font-weight: 500;
}

.dashboard .in_box .box p {
  font-weight: 500;
  margin-bottom: 0;
}

.dashboard .in_boxchg {
  background: unset;
}

.dashboard .dash_box {
  border-radius: 60px;
  padding: 20px;
  background: radial-gradient(82.61% 82.61% at 26.3% 32.03%, #3C287D 0%, #130042 100%);
}

.dashboard .dash_box .box {
  border-radius: 45px;
  background-image: radial-gradient(77.69% 100% at 47.69% 0%, #3C287D 0%, #130042 100%);
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: .3s;
}

.dashboard .dash_box .box:hover {
  transition: .3s;
  background-image: radial-gradient(90.31% 91.28% at 46.93% -14.14%, #CAF99B 0%, #C241FF 96.69%);
}

.dashboard .dash_box .box p {
  color: #FFF;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}

.dashboard .dash_box .box h6 {
  font-size: 24px;
  color: #CAF99B;
  font-weight: 600;
  margin-bottom: 0;
}

.dashboard .dash_box .box h5 {
  font-size: 36px;
  color: #FFF;
  font-weight: 700;
  background: radial-gradient(612.14% 305.92% at 14.22% -47.32%, #FFE9FF 0%, #C242FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard .dash_box .box span {
  color: #FCFBFF;
  font-size: 14px;
  font-weight: 500;
}

.dashboard .dash_box .bdr {
  border-bottom: 1px solid #3C287D;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.dashboard .dash_box .box:hover .bdr {
  border-bottom: 1px solid #fff;
}

.dashboard .dash_box .box:hover h6 {
  color: #3B2572;
}


.dashboard .tree {
  padding: 40px 70px !important;
  background: url("./assets/images/tree_bg.png") no-repeat center;
}

.dashboard .t_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard .t_flx .input-group {
  width: 35%;
  border-radius: 60px;
  background: linear-gradient(110deg, #3C287D 27.59%, #130042 113.04%);
}

.dashboard .t_flx input {
  background: transparent;
  border: 0;
  box-shadow: none !important;
  padding: 12px;
  padding-right: 0;
  color: #fff;
}

.dashboard .t_flx input::placeholder {
  color: #FCFBFF;
}

.dashboard .t_flx .input-group-text {
  background: transparent;
  border: 0;
  box-shadow: none !important;
}
.dashboard .validity_box{
  border-radius: 60px;
  background: url("./assets/images/validity_bg.png") no-repeat center;
  background-size: 110% 110%;
  padding: 30px 70px;
}
.dashboard .validity_box .b_flx {
  display: flex; 
}
.dashboard .validity_box .progress {
  border-radius: 20px;
  width: 100%;
  margin: 15px 0 20px;
  margin-right: 10px;
  background: #DB8FFF;
  height: 22px !important;
}
.dashboard .validity_box .progress-bar {
  background: #CAF99B;
  border-radius: 30px;
}
.dashboard .validity_box h5{
  font-size: 24px;
font-style: normal;
font-weight: 600;
color: #FCFBFF;
}
.dashboard .validity_box .flx{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFF; 
font-size: 18px;
font-style: normal;
font-weight: 500;
}
.dashboard .validity_box .flx p{
  margin-bottom: 0;
}
.dashboard .validity_box .flx .ylw{
  color: #CAF99B;
}
.history {
  padding: 40px 70px !important;
}

.history .table>:not(caption)>*>* {
  color: unset !important;
  background: unset;
}

.history table {
  border-color: #5148a100;
  color: #FCFBFF !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
}

.history thead {
  border-bottom: 0px solid transparent;
  border-radius: 10px;
  color: #CAF99B;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.history thead th {
  background: linear-gradient(180deg, #3C287D -75%, #130042 374.14%) !important;
}

.history td:nth-last-child(1),
.history th:nth-last-child(1) {
  padding-right: 100px !important;
}

.history td:nth-child(1),
.history th:nth-child(1) {
  padding-left: 60px !important;
}

.history td:nth-child(3),
.history th:nth-child(3) {
  padding-left: 60px !important;
}

.history td,
.history th {
  padding: 20px 15px !important;
}




.plan_pool {
  width: 1156px;
  margin-top: 40px;
}

.plan_pool img {
  z-index: 1;
  outline: 0;
}

.plan_pool_head {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 56px;
  position: relative;
  margin: 10px auto 40px;
}

.plan_pool_head::after {
  content: "";
  background-color: #533BA1;
  width: 1px;
  height: 57%;
  position: absolute;
  bottom: -40px;
  left: -2px;
  right: 0;
  margin: 0 auto;
}

.plan_pool_head_child {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: relative;
  margin: 29px 5px 0;
}

.plan_pool_first_row .plan_pool_head_child {
  width: 100px;
  height: 40px;
  font-size: 36px;
}

.plan_pool_first_row {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.plan_pool_first_row::after {
  content: "";
  background-color: #533BA1;
  width: 50%;
  width: 66.8%;
  height: 1px;
  position: absolute;
  top: 1px;
}

.plan_pool_head_child::before {
  content: "";
  width: 1px;
  height: 78%;
  position: absolute;
  top: -27px;
  background: #533ba1;
}

.plan_pool_second_row .plan_pool_head_child {
  width: 32px;
  height: 45px;
  font-size: 30px;
  border-radius: 12px;
  margin-top: 26px;
}

.plan_pool_second_row>div {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin-top: 30px;
  width: 50%;
  width: 32.7%;
}

.plan_pool_second_row {
  display: flex;
  justify-content: space-around;
}

.plan_pool_first_row .plan_pool_head_child::after {
  content: "";
  background-color: #533BA1;
  width: 1px;
  height: 60%;
  position: absolute;
  bottom: -32px;
}

.plan_pool_second_row>div::before {
  content: "";
  background-color: #533BA1;
  width: 67%;
  height: 1px;
  position: absolute;
  top: -1px;
}

.plan_pool_second_row .plan_pool_head_child::after {
  content: "";
  background-color: #533BA1;
  width: 1px;
  height: 60%;
  position: absolute;
  bottom: -32px;
}

.plan_pool_second_row img {
  min-width: 45px;
  height: 45px;
}

.plan_pool_third_row img {
  min-width: unset;
  height: unset;
}



.plan_pool_third_row {
  display: flex;
  justify-content: space-around;
}

.plan_pool_third_row .plan_pool_head_child::after {
  width: 0px;
}

/* .plan_pool_third_row>div::before {
  content: "";
  background-color: #533BA1;
  width: 50%;
  height: 1px;
  position: absolute;
}  */
.plan_pool_third_row>div {
  display: flex;
}

.plan_pool_third_row>div>div {
  display: flex;
}

.joinnow {
  min-height: calc(100vh - 236px);
}

.joinnow .bg_box {
  border-radius: 20px;
}

.joinnow .bg_box ::placeholder {
  color: #858585 !important;
}

.joinnow h3 {
  background: linear-gradient(249deg, #C242FF 0%, #B2BCFF 140.19%, #CAF99B 120.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dashboard .booster {
  padding: 50px 70px;
}

.dashboard .booster .abv_flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dashboard .booster .abv_flx span {
  font-size: 20px;
}

.dashboard .booster .box {
  text-align: left;
  display: block;
  padding: 40px 30px;
  margin-top: 24px;
  border-radius: 45px;
  background-image: radial-gradient(77.69% 100% at 47.69% 0%, #3C287D 0%, #130042 100%) !important;
}

.dashboard .booster .box h5 {
  margin-bottom: 0;
  line-height: 30px;
  font-size: 26px;
}

.dashboard .booster .box h6 {
  color: #CAF99B !important;
}

.dashboard .booster .box .fl_x {
  display: flex;
}

.dashboard .booster .box .bdr {
  border: 0;
}

.dashboard .booster .box .b_dr {
  position: relative;
  width: 45%;
}

.dashboard .booster .box .b_dr::before {
  content: "";
  background: #3C287D;
  position: absolute;
  width: 1px;
  height: 70%;
  margin: auto 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.dashboard .booster .box .amount {
  text-align: center;
  width: 55%;
}

.dashboard .booster .box .empty {
  background: radial-gradient(612.14% 305.92% at 14.22% -47.32%, #FFE9FF 0%, #C242FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
  line-height: 5px;
}

.dashboard .booster .box .progress {
  border-radius: 20px;
  width: 100%;
  margin: 15px 0;
  margin-right: 10px;
  background: #352173;
  height: 18px !important;

}

.dashboard .booster .box .progress-bar {
  background: #603BBC;
}

.dashboard .booster .box .b_flx {
  display: flex;
  align-items: center;
}

.dashboard .booster .box .b_flx p {
  font-size: 20px;
  margin-bottom: 0;
  color: #CAF99B;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dashboard .booster .box .flx {
  display: flex;
  align-items: center;
}

.dashboard .booster .box .tic {
  border-radius: 30px;
  background: radial-gradient(135% 135% at 50% -7%, #5E4DFF 0%, #C242FF 100%);
  box-shadow: 0px 16px 28px 0px rgba(194, 66, 255, 0.35);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.dashboard .booster .claim{
  font-size: 16px !important;
}
.dashboard .booster .current {
  background: radial-gradient(90.31% 91.28% at 46.93% -14.14%, #CAF99B 0%, #C241FF 96.69%) !important;
}

.dashboard .booster .current p {
  color: #3B2572;
}

.dashboard .booster .current h5 {
  color: #FCFBFF;
  background: unset;
  -webkit-text-fill-color: unset;
}

.dashboard .booster .current h6 {
  color: #3B2572 !important;
}

.dashboard .booster .current .progress {
  background: #DB8FFF;
}

.dashboard .booster .current .progress-bar {
  border-radius: 45px;
  background: #CAF99B;
}
.dashboard .booster .current .empty{
  -webkit-text-fill-color: #fff;
}
.dashboard .booster .current .primary_btn{
  background: #3c287d;
}
.dashboard .booster .inactive{
  opacity: 0.5;
  background: #130042 !important;
}
.dashboard .booster .inactive .empty{
  line-height: 30px;
  -webkit-text-fill-color: white;
}
.dashboard .booster .inactive h5 {
  color: #FCFBFF;
  background: unset;
  -webkit-text-fill-color: unset;
}
.dashboard .booster .inactive h6,
.dashboard .booster .box .b_flx p {
  color: #fff !important;
}
.dashboard .income_boxes{
  background: transparent;
  padding: 0
}
.dashboard .income_boxes .box{
  padding: 40px 70px;
  border-radius: 45px;
  width: 100%;
background: radial-gradient(51.37% 100% at 47.69% 0%, #3C287D 0%, #130042 100%) !important;
}

.react-tooltip {
  z-index: 4;
  background: #6f4bff !important;
}



.timer_panel span {
  font-size: 20px !important;
}
.brand_logo{
  width: 170px;
}
.dropdown-item:focus, .dropdown-item:hover{
  background: #0000001f;
  color: #fff;
  text-decoration: none;
}

@media (max-width:1399px) {
  .dashboard .income_boxes .box {
    padding: 40px;
  }
  .dashboard .in_box {
    background-size: 100% 100%;
  }
  .dashboard .booster .current h5{
    font-size: 24px;
  }
  .dashboard .booster .box{
    padding: 25px 20px;
  }
  .dashboard .booster .box .amount { 
    width: 60%;
  }
  .dashboard .booster .box .b_dr { 
    width: 40%;
  }
  .total .tot_box {
    padding: 50px 60px;
  }

  .total .img_box {
    min-width: 80px;
    height: 80px;
  }

  .features .box {
    padding: 20px;
  }

  .features .img_box {
    top: -21px;
  }
}

@media (max-width:1199px) {
  .main_navbar .container {
    background-size: 160%;
  }
  .dashboard .income_boxes .box{
    flex-direction: column !important; 
  }
  .dashboard .income_boxes .box .align-items-end{ 
    justify-content: center;
  }
  .dashboard .income_boxes .box .text-end{
    text-align: center !important;
  }
  .dashboard .income_boxes .box .primary_btn{
     margin-top: 10px;
  }
  .dashboard .validity_box{
    padding: 30px 50px;
  }
  .dashboard .in_box .box .img_box {
    margin-right: 15px;
  }

  .dashboard .in_box .box h5 {
    font-size: 26px;
  }

  .dashboard .in_box {
    padding: 35px 10px;
  }

  .dashboard .in_box .col-lg-3:not(:nth-last-child(1)) .box::before {
    right: -10px;
  }

  .dashboard .dash_box .box h5 {
    font-size: 28px;
  }

  .total .tot_box h3 {
    font-size: 48px;
  }

  .tree_sec .in_box {
    width: 86%;
    padding: 30px 20px;
  }

  .features .box h6 {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .features .box p {
    font-size: 14px;
  }

  .features .img_box {
    top: 9px;
    width: 61px;
    height: 62px;
  }
}

@media (max-width:991px) {
  .head {
    max-width: 100%;
  }

  .h2tag {
    font-size: 42px;
  }
  .dashboard .validity_box {
    border-radius: 30px;
  }
  .dashboard .validity_box .b_flx{
    flex-direction: column;
  }
  .dashboard .validity_box .b_flx>div:nth-child(1){
    margin-bottom: 10px;
  }
  .dashboard .booster .abv_flx{
    flex-direction: column;
  }
  .dashboard .booster .abv_flx div{
    display: flex;
    flex-direction: column;
  }
  .dashboard .booster {
    padding: 20px;
}
  .navbar_right {
    flex-direction: column;
  }

  .navbar_right .primary_btn {
    margin: 10px 0 0 !important;
  }

  .main_navbar .container {
    background: rgb(40 25 89 / 100%);
  }

  .dashboard .h_flx {
    flex-direction: column;
  }

  .history td:nth-child(1),
  .history th:nth-child(1) {
    padding-left: 20px !important;
  }

  .dashboard .tree {
    padding: 40px 30px !important;
    background-size: 100% 100%;
  }

  .dashboard .bg_box {
    padding: 40px 30px !important;
    border-radius: 30px;
  }

  .dashboard .dash_box {
    border-radius: 30px;
  }

  .dashboard .dash_box .box {
    border-radius: 24px;
  }

  .dashboard .in_box {
    padding: 35px 15px;
    background-size: 110% 110%;
    border-radius: 26px;
  }

  .dashboard .in_box .col-lg-3:not(:last-child) .box {
    justify-content: flex-start !important;
    margin-bottom: 20px;
  }

  .dashboard .in_box .col-lg-3:not(:nth-last-child(1)) .box::before {
    display: none;
  }

  .dashboard .dash_box .col-lg-3:not(:nth-last-child(1)) .box {
    margin-bottom: 15px;
  }

  .dashboard .t_flx {
    flex-direction: column;
  }

  .dashboard .t_flx .input-group {
    width: 98%;
    margin-top: 10px;
  }

  .page_header {
    padding-top: 80px;
  }

  .page_header::before {
    height: 700px;
    top: -200px;
    background-size: cover;
  }

  .page_header .img_box {
    display: none;
  }

  .tree_sec .bg {
    background-size: cover;
  }

  .tree_sec .in_box h5 {
    font-size: 38px;
  }

  .tree_sec .in_box {
    width: 65%;
    margin-top: 30px;
  }

  .features .box {
    background: #130042 !important;
    border: 1px solid #3c287d;
    height: unset;
    display: block;
    padding: 30px 35px;
    margin-bottom: 15px;
    border-radius: 20px;
  }

  .features .box .img_box {
    position: unset;
    margin: unset;
    margin-bottom: 20px;
  }

  .total .tot_box {
    background-size: 110% 110%;
    border-radius: 30px;
    padding: 20px 30px;
  }

  .total .img_box {
    min-width: 60px;
    height: 60px;
    padding: 10px;
  }

  .total .img_box img {
    height: 26px;
  }

  .total .tot_box h3 {
    font-size: 38px;
  }

  .total .right_box {
    border-left: 0;
    margin-top: 30px;
    padding-left: 0px;
  }
}

@media (max-width:767px) {
  .h2tag {
    font-size: 35px;
  }
  .dashboard .validity_box .flx{
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard .h_flx .box {
    flex-direction: column; 
    border-radius: 20px;
    align-items: flex-start;
  }
  .dashboard .h_flx .box p{
    margin-left: 0;
  }
  .dashboard h3 {
    font-size: 28px;
  }
  .dashboard .booster .box .fl_x{
    flex-direction: column;
  }
  .dashboard .booster .box .b_dr ,
  .dashboard .booster .box .amount {
    text-align: left;
    width: 100%;
}
.dashboard .booster .box .b_dr::before{
  display: none;
}
.dashboard .h_flx .box img {
  margin-bottom: 5px;
}
  .levels table {
    font-size: 16px;
  }

  .page_header h1 {
    font-size: 38px;
  }

  .total .box {
    flex-direction: column;
    align-items: flex-start;
  }

  .total .img_box {
    margin-bottom: 15px;
  }

  .total .tot_box h3 {
    font-size: 32px;
  }

  .platform_activity .activity_box {
    padding: 17px;
  }

  .platform_activity .flxbox {
    flex-wrap: wrap;
  }

  .platform_activity .flxbox div {
    margin: 5px;
    margin-right: 15px;
    margin-left: 0;
  }

  .platform_activity .activity_box_flx {
    flex-direction: column;
    align-items: baseline;
    padding: 20px;
  }

  .platform_activity .rightbtn p {
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-top: 17px;
  }
}

@media (max-width:575px) {
  .main_navbar .container {
    margin: 0 10px;
    padding: 8px 20px;
  }
  .dashboard .validity_box{
    padding: 30px;
  }
  .dashboard .validity_box .flx{
    font-size: 16px;
  }
  .dashboard .validity_box h5 {
    font-size: 21px;
  }

  .dashboard .bg_box {
    padding: 20px 10px !important;
  }

  .tree_sec .bg {
    padding: 30px 20px;
    border-radius: 30px;
  }

  .dashboard .tree,
  .dashboard .history {
    padding: 20px !important;
  }

  .tree_sec .in_box {
    width: 100%;
    border-radius: 20px;
  }

  .levels td:nth-child(1),
  .levels th:nth-child(1) {
    padding-left: 30px !important;
  }

  .dashboard .h_flx .box p {
    font-size: 13px;
    margin-right: 8px;
    word-break: break-word;
  }

  .dashboard .h_flx .box img {
    height: 14px;
  }
}

.isuser {
  filter: blur(2px);
  opacity: 0.8;
}